
import { apideliveryWay, apideliveryWayset } from '@/api/setting'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomName from './components/custom-name.vue'

@Component({
    components: {
        CustomName
    }
})
export default class DeliveryIndex extends Vue {
    $refs!: { customName: any }
    form = {
        express: {
            is_express: 0,
            express_name: '2'
        },
        selffetch: {
            is_selffetch: 0,
            selffetch_name: '3'
        }
    }
    handleChange($event: number, type: 'is_express' | 'is_selffetch') {
        if (!$event && !this.form.express.is_express && !this.form.selffetch.is_selffetch) {
            this.$message.warning('至少启用一种配送方式')
            this.form.express.is_express = 1
            this.form.selffetch.is_selffetch = 1
            return
        }
        apideliveryWayset({ ...this.form.express, ...this.form.selffetch })
    }
    getLogisticsConfig() {
        apideliveryWay().then(res => {
            this.form = res
        })
    }
    openDialog(way: string) {
        console.log(this.form);
        
        this.$refs.customName.openDialog(way, this.form)
    }
    created() {
        this.getLogisticsConfig()
    }
}
