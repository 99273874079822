
import { Component, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apideliveryWayset } from '@/api/setting'

@Component({
    components: {
        LsDialog
    }
})
export default class GoodsCollectionConfig extends Vue {
    form = {
        express: {
            is_express: 0,
            express_name: ''
        },
        selffetch: {
            is_selffetch: 0,
            selffetch_name: ''
        }
    }
    // 1-快递发货 2-上门自提
    mode = '快递发货'
    customName = ''
    $refs!: { dialog: any }
    closeDialog() {
        this.$refs.dialog.close()
    }
    openDialog(way: string, form: any) {
        console.log(way, form);
        
        this.mode = way
        this.form = form
        switch (way) {
            case '快递发货':
                this.customName = form.express.express_name
                break
            default:
                this.customName = form.selffetch.selffetch_name
                break
        }
        this.$refs.dialog.open()
    }

    async handleConfirm() {
        switch (this.mode) {
            case '快递发货':
                this.form.express.express_name = this.customName
                break
            default:
                this.form.selffetch.selffetch_name = this.customName
                break
        }
        await apideliveryWayset({ ...this.form.express, ...this.form.selffetch })
        this.closeDialog()
    }
}
